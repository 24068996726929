import { useStaticQuery, graphql } from 'gatsby'

import { ListJobs } from '../API'

/**
 * List all the jobs from contentful
 * @returns
 */
const useListJobs = () => {
  const data: ListJobs = useStaticQuery(graphql`
    query ListJobs {
      allContentfulJobs(filter: { active: { eq: true } }) {
        nodes {
          id
          intro
          slug
          title
          headerImage {
            fluid {
              srcSet
            }
          }
        }
      }
    }
  `)
  return data
}

export default useListJobs
