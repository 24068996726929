import React from 'react'
import styled from 'styled-components/macro'

// Components
import Grid from '@components/ViewGridComponent'
import HeadingSection from '@components/HeadingSectionComponent'
import SectionComponent from '@components/SectionComponent'
import { FadeAndRotate, FadeIn } from '@components/Animations'
import Spacer from '@components/SpacerComponent'
import { ImageComponent as Image } from '@components/ImageComponent'
import SpacerComponent from '@components/SpacerComponent'
import ImageSpacer from '@components/ImageSpacerComponent/ImageSpacerComponent'
import {
  Heading,
  Heading2,
  Highlight,
  Paragraph,
  Title1,
} from '@components/Typography'

// Hooks
import useSetBackgroundColor from '@hooks/useSetBackgroundColor'
import useIsMobile from '@hooks/useIsMobile'

// Assets
import PotteryGang from '@assets/images/pottery-gang.jpg'
import PotteryGangWebp from '@assets/images/webp/pottery-gang.webp'
import SamWithGus from '@assets/images/sam-with-rocket-and-gus.jpg'
import SamWithGusWebp from '@assets/images/webp/sam-with-rocket-and-gus.webp'
import AgileKinetic from '@assets/images/agile-kinetic-mockup.png'
import AgileKineticWebp from '@assets/images/webp/agile-kinetic-mockup.webp'
import TfwPoster from '@assets/images/tfw-poster-train.png'
import TfwPosterWebp from '@assets/images/webp/tfw-poster-train.webp'

const Who: React.FC = () => {
  const { ref } = useSetBackgroundColor()
  const isMobile = useIsMobile()
  const headingColumn = isMobile ? '1 / 13' : '1 / 7'

  return (
    <SectionComponent ref={ref} id='jobs'>
      <Grid.Row>
        <HeadingSection
          verticalHeading='Jobs'
          heading={
            <FadeIn>
              <Title1 centered={!isMobile}>Work with us.</Title1>
            </FadeIn>
          }
          content={[
            <FadeIn>
              <SpacerComponent top='lg' />
              <Paragraph centered={!isMobile} size='lg'>
                We’re a small team with a big bloody mission. And that’s to
                build tech with purpose, one project at a time. Interested in
                joining us?
              </Paragraph>
            </FadeIn>,
          ]}
          hasLink={false}
        />
      </Grid.Row>
      <Grid.Row>
        <SpacerComponent bottom={isMobile ? 'xl2' : 'xl3'} />
        <Grid.Item columnStart={1} columnEnd={13}>
          <FadeAndRotate direction='middle'>
            <ImageSpacer>
              <Image
                alt='BigLemon Team painting pottery'
                src={PotteryGangWebp}
                sources={[
                  { srcSet: PotteryGangWebp, type: 'image/webp' },
                  { srcSet: PotteryGang, type: 'image/jpeg' },
                ]}
              />
            </ImageSpacer>
          </FadeAndRotate>
        </Grid.Item>
      </Grid.Row>
    </SectionComponent>
  )
}

export default Who
