import React from 'react'
// Components
import SeoComponent from '@components/SeoComponent'
import { Who, OurJobs } from '@views/JobsSections'

import { ThemeProvider } from 'styled-components'

import { darkPurpleTheme, paleBlueTheme } from '../themes'

export default function Jobs() {
  return (
    <>
      <SeoComponent
        title='Jobs at Big Lemon'
        description='Come and join the Big Lemon family. View our latest roles or get in touch for more info.'
      />
      <ThemeProvider theme={darkPurpleTheme}>
        <Who />
      </ThemeProvider>
      <ThemeProvider theme={paleBlueTheme}>
        <OurJobs />
      </ThemeProvider>
    </>
  )
}
