import React from 'react'

// Components
import InvertingImageSection from '@components/InvertingImageComponent'
import Section from '@components/SectionComponent'
import Spacer from '@components/SpacerComponent'
import { Heading, Heading3, Highlight, Paragraph } from '@components/Typography'
import Grid from '@components/ViewGridComponent'

// Hooks
import useListJobs from '@hooks/useListJobs'
import SquaredLinkComponent from '@components/SquaredLinkComponent'
import SpacerComponent from '@components/SpacerComponent'
import styled from 'styled-components'

type OurJobsProps = {}

const OurJobs: React.FC<OurJobsProps> = () => {
  const { allContentfulJobs } = useListJobs()

  const hasContent = allContentfulJobs.nodes.length > 0

  return (
    <Section>
      <Grid.Item column='1 / -1'>
        {hasContent ? (
          <Heading centered>
            <Highlight>We’re hiring!</Highlight> View our current vacancies
          </Heading>
        ) : (
          <Heading centered>
            <Highlight> Uh-oh!</Highlight> We’re not hiring at the moment.
          </Heading>
        )}

        <Spacer bottom={hasContent ? 'xl2' : 'base'} />
      </Grid.Item>
      {hasContent ? (
        <InvertingImageSection
          data={allContentfulJobs.nodes?.map(job => {
            return {
              id: job.id,
              heading: job.title,
              caption: 'Remote',
              paragraph: job.intro,
              to: `/jobs/${job.slug}`,
              image: {
                alt: job.headerImage.title,
                portrait: true,
                sources: [{ srcSet: job.headerImage.fluid.srcSet }],
              },
            }
          })}
        />
      ) : (
        <NoJobs column='1 / 13' alignSelfCenter>
          <Paragraph centered>
            But don’t let that stop you from sending your CV if you'd like to be
            considered when a role comes up.
          </Paragraph>
          <SpacerComponent top='base' />
          <SquaredLinkComponent
            href='mailto:jobs@biglemon.co.uk'
            label='Send CV'
          />
        </NoJobs>
      )}
    </Section>
  )
}

export default OurJobs

const NoJobs = styled(Grid.Item)`
  display: flex;
  flex-direction: column;
  align-items: center;
`
